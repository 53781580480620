import React from "react"
import Layout from "../components/Layout" 

const NotFoundPage = () => {

  return (
    <>
      <Layout>

        <div className="page-wrapper cv">
          <div className="main-image">
          </div>
          <h1>404</h1>
          <div className="details">
            <div className="breadcrumb">/NOT_FOUND</div>
          </div>
          <br/>
          <div>
            <a className="button" href="/">Retour</a>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default NotFoundPage
